.word-question-screen {
    text-align: center;
}

.word-question-flashcard {
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: 80%;
    max-width: 425px;
    border: 1px solid black;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: inline-block;
    box-shadow: 1px 1px 1px black;
    border-radius: 5px;
}

.guess-word {
    font-size: 30px;
    margin-bottom: 10px;
}

.guess-label {
    display: block;
    margin: 5px;
}

.guess {
    font-size: 24px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    background-color: #f1f0cf;
    border-radius: 3px;
    display: block;
    width: 80%;
}

.guess-submit {
    display: block;
    margin: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 18px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;
    width: 200px;
    text-decoration: none;
    color: white;
    box-shadow: 1px 1px 1px black;
    background-color: rgb(59, 89, 170);
}

.difficulty-easy {
    background-color: rgb(169, 209, 188);
}

.difficulty-normal {
    background-color: rgb(245, 229, 200);
}

.difficulty-hard {
    background-color: rgb(238, 160, 160);
}

.learn-guess-input {
    margin: auto;
    background-color: #f1f0cf;
    border-radius: 3px;
    display: block;
    width: 80%
}
