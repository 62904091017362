.login-logout-header {
    font-size: 24px;
    margin: 10px;
    text-decoration: none;
    color: white;
    background-color: rgb(59, 89, 170);
    padding: 10px;
    width: 160px;
    display: inline-block;
    border: 2px solid white;
    border-radius: 5px;
    box-shadow: 4px 4px 6px black;
    text-shadow: 2px 2px 3px black;
}

.site-title-header {
    font-size: 36px;
    margin-top: 0px;
    text-decoration: none;
    color: white;
    text-shadow: 2px 2px 10px black;
}

header {
    text-align: center;
    background-color: darkslategray;
    background-image:
        linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.7)
        ),
        url("../../images/back.jpg");
    background-size: cover;
    padding: 10px;
}

.login-logout-user-welcome {
    font-size: 28px;
    margin: 10px;
    text-decoration: none;
    color: white;
    text-shadow: 2px 2px 10px black;
}