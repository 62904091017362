@import url(https://fonts.googleapis.com/css?family=Ubuntu);
.login-logout-header {
    font-size: 24px;
    margin: 10px;
    text-decoration: none;
    color: white;
    background-color: rgb(59, 89, 170);
    padding: 10px;
    width: 160px;
    display: inline-block;
    border: 2px solid white;
    border-radius: 5px;
    box-shadow: 4px 4px 6px black;
    text-shadow: 2px 2px 3px black;
}

.site-title-header {
    font-size: 36px;
    margin-top: 0px;
    text-decoration: none;
    color: white;
    text-shadow: 2px 2px 10px black;
}

header {
    text-align: center;
    background-color: darkslategray;
    background-image:
        -webkit-linear-gradient(
            top,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.7)
        ),
        url(/static/media/back.a68d48bb.jpg);
    background-image:
        linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.7)
        ),
        url(/static/media/back.a68d48bb.jpg);
    background-size: cover;
    padding: 10px;
}

.login-logout-user-welcome {
    font-size: 28px;
    margin: 10px;
    text-decoration: none;
    color: white;
    text-shadow: 2px 2px 10px black;
}


.form-login-register {
    display: block;
    margin: auto;
    text-align: center;
}

.login-register-card {
    width: 80%;
    max-width: 500px;
    display: block;
    margin: auto;
    text-align: center;
    background-color: whitesmoke;
    border: 1px solid black;
    box-shadow: 1px 1px 1px black;
    border-radius: 5px;
    padding: 20px;
}

.button-form-submit {
    display: block;
    margin: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 22px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right:10px;
    border: 1px solid black;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    box-shadow: 1px 1px 1px black;
    background-color: rgb(59, 89, 170);
    text-shadow: 2px 2px 2px black;
    font-family: 'Playfair Display', serif;
    box-shadow: 1px 1px 1px black;
}

.form-label-input {
    margin: 5px;
    font-size: 18px;
    display: block;
}

.form-input {
    margin: auto;
    font-size: 18px;
    background-color: #f1f0cf;
    border-radius: 3px;
    display: block;
    width: 80%
}

.login-register-header {
    text-align: center;
    margin-top: 0px;
}

.login-register-section {
    padding: 10px;    
}

.form-entry {
    text-align: center;
    margin: auto;
    margin-bottom: 15px;
}

.error-bar {
    color: red;
    font-size: 24px;
}
.dashboard-screen {
    padding: 10px;
}

.dashboard-language-header {
    text-align: center;
    font-size: 40px;
    margin-top: 0px;
    padding-top: 20px;
}

.dashboard-learning-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 24px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;
    width: 200px;
    text-decoration: none;
    color: white;
    box-shadow: 1px 1px 1px black;
    background-color: rgb(80, 118, 223);
}

.dashboard-vocab-header {
    text-align: center;
    font-size: 30px;
}

.dashboard-vocab-list {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    text-align: center;
}

.dashboard-vocab-word {
    border: 1px solid black;
    padding: 10px;
    margin: 5px;
    display: inline-block;
    box-shadow: 1px 1px 1px black;
    border-radius: 5px;
}

.vocab-word-score {
    margin-top: 0px;
    margin-bottom: 15px;
    font-size: 16px;
}

.vocab-word-translation {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 18px;
}

.dashboard-vocab-word h4 {
    margin-top: 15px;
    margin-bottom: 0px;
    font-size: 24px;
}

.dashboard-answer-display {
    text-align: center;
    margin-bottom: 0px;
    padding-bottom: 10px;
}

.difficulty-easy {
    background-color: rgb(126, 187, 154);
}

.difficulty-normal {
    background-color: wheat;
}

.difficulty-hard {
    background-color: rgb(238, 136, 136);
}
.word-question-screen {
    text-align: center;
}

.word-question-flashcard {
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: 80%;
    max-width: 425px;
    border: 1px solid black;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: inline-block;
    box-shadow: 1px 1px 1px black;
    border-radius: 5px;
}

.guess-word {
    font-size: 30px;
    margin-bottom: 10px;
}

.guess-label {
    display: block;
    margin: 5px;
}

.guess {
    font-size: 24px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    background-color: #f1f0cf;
    border-radius: 3px;
    display: block;
    width: 80%;
}

.guess-submit {
    display: block;
    margin: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 18px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;
    width: 200px;
    text-decoration: none;
    color: white;
    box-shadow: 1px 1px 1px black;
    background-color: rgb(59, 89, 170);
}

.difficulty-easy {
    background-color: rgb(169, 209, 188);
}

.difficulty-normal {
    background-color: rgb(245, 229, 200);
}

.difficulty-hard {
    background-color: rgb(238, 160, 160);
}

.learn-guess-input {
    margin: auto;
    background-color: #f1f0cf;
    border-radius: 3px;
    display: block;
    width: 80%
}

.word-answer-screen {
    text-align: center;
}

.word-feedback-card {
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: 80%;
    max-width: 425px;
    border: 1px solid black;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: inline-block;
    box-shadow: 1px 1px 1px black;
    border-radius: 5px;
}

.next-word-button {
    display: block;
    margin: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 18px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;
    width: 200px;
    text-decoration: none;
    color: white;
    box-shadow: 1px 1px 1px black;
    background-color: rgb(59, 89, 170);
}
span, p, label {
    font-family: 'Alegreya', serif;
}

a, h1, h2 {
    font-family: 'Playfair Display', serif;
}

.error-message{
  color: red;
  font-size: 20px;
  text-align: center;
}

html {
  background: -webkit-linear-gradient(left, rgb(60, 114, 60) 0%, whitesmoke 25%, whitesmoke 75%, rgb(168, 49, 49) 100%);
  background: linear-gradient(to right, rgb(60, 114, 60) 0%, whitesmoke 25%, whitesmoke 75%, rgb(168, 49, 49) 100%);
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --font-size: calc(10px + 1vmin);
}

