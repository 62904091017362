span, p, label {
    font-family: 'Alegreya', serif;
}

a, h1, h2 {
    font-family: 'Playfair Display', serif;
}

.error-message{
  color: red;
  font-size: 20px;
  text-align: center;
}

html {
  background: linear-gradient(to right, rgb(60, 114, 60) 0%, whitesmoke 25%, whitesmoke 75%, rgb(168, 49, 49) 100%);
}